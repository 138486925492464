import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { AdminComponent } from './features/admin/admin.component';
import { MyAccountComponent } from './features/my-account/my-account.component';
import { DiligenceComponent } from './features/diligence/diligence.component';
import { TosComponent } from './features/tos/tos.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { AdminGuard } from './shared/guards/admin.guard';
import { NotVerifiedComponent } from './features/not-verified/not-verified.component';
import { SearchFormComponent } from './features/search-form/search-form.component';
import { AccountsComponent } from './features/accounts/accounts.component';


const routes: Routes = [
  { 
    path: '', 
    component: HomeComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'myaccount', 
    component: MyAccountComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'accounts', 
    component: AccountsComponent, 
    canActivate: [AdminGuard] 
  },
  { 
    path: 'dueDiligence/:id', 
    component: DiligenceComponent, 
    canActivate: [AuthGuard] 
  },
  { path: 'tos', component: TosComponent },
  { path: 'notVerified', component: NotVerifiedComponent },
  { 
    path: 'search', 
    component: SearchFormComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'admin', 
    component: AdminComponent, 
    canActivate: [AdminGuard] 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }