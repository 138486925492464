import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PostDiligenceParams } from 'src/app/core/models/params';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ExpiryDateFormatterDirective } from 'src/app/core/directives/format-expiration.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@auth0/auth0-angular';
import { EMPTY, Observable, catchError, combineLatestWith, of, switchMap, take, tap } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { MyAuthService } from 'src/app/core/services/auth.service';
import { Package } from '../.../../../../core/models/package';
import { environment } from 'src/environments/environment';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TosComponent } from 'src/app/features/tos/tos.component';
import {packages} from 'src/app/core/models/packages';

@Component({
  selector: 'app-post-diligence-dialog',
  templateUrl: './post-diligence-dialog.component.html',
  styleUrls: ['./post-diligence-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule, CommonModule, MatDialogModule, MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule, ReactiveFormsModule,
    MatFormFieldModule, MatProgressSpinnerModule, MatIconModule, ExpiryDateFormatterDirective, MatCheckboxModule
  ],
  providers: [MatSnackBar]
})
export class PostDiligenceDialogComponent implements OnInit {
  apiService = inject(ApiService);
  auth = inject(AuthService);
  myAuthService = inject(MyAuthService);
  snackBar = inject(MatSnackBar);
  dialog = inject(MatDialog);
  packages = packages;

  isAdmin$: Observable<boolean> = this.myAuthService.isAdmin();

  formGroup!: FormGroup;

  packageChosen!: Package;
  userHasPayed = false;
  loading = false;
  error = false;
  ubo_selected = false;
  termsAccepted = false;
  hasCredits = false;
  checkingCredits = true;

  onCheckboxChange(event: MatCheckboxChange): void {
    const isChecked = event.checked;
    this.ubo_selected = isChecked;
    
    const basePrice = this.hasCredits ? 0 : this.packageChosen.price;
    const uboPrice = isChecked ? (this.packageChosen.ubo_add_on || 0) : 0;
    
    this.formGroup.patchValue({
      xAmount: basePrice + uboPrice
    });
  }

  constructor(
    public dialogRef: MatDialogRef<PostDiligenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }

  onSubmit() {
    if (this.formGroup.getRawValue().xAmount > 0) {
      this.onPaySubmit();
    } else {
      this.submitDiligence();
    }
  }

  onPaySubmit() {
    if (this.formGroup.valid) {
      this.loading = true;
      this.error = false;
      this.auth.user$.pipe(
        switchMap(user => this.apiService.postSale({
          xCVV: this.formGroup.value.xCVV,
          xExp: this.formGroup.value.xExp.replace(/\D/g, ''),
          xCardNum: this.formGroup.value.xCardNum,
          xAmount: this.formGroup.getRawValue().xAmount,
          xEmail: user?.email,
          xBillFirstName: this.formGroup.value.xBillFirstName,
          xBillLastName: this.formGroup.value.xBillLastName,
          xBillStreet: this.formGroup.value.xBillStreet,
          xBillCity: this.formGroup.value.xBillCity,
          xBillState: this.formGroup.value.xBillState,
          xBillZip: this.formGroup.value.xBillZip,
          xBillMobile: this.formGroup.value.xBillMobile,
        })),
        catchError(err => {
          this.loading = false;
          this.error = true;
          return EMPTY;
        }),
        combineLatestWith(this.isAdmin$),
      ).subscribe(([transactionResponse, isAdmin]) => {
        this.loading = false;
        if (transactionResponse?.xStatus === 'Approved' || isAdmin) {
          this.userHasPayed = true;
          this.submitDiligence();
        } else {
          this.error = true;
        }
      });
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        const control = this.formGroup.get(key);
        control?.markAsTouched();
      });
    }
  }

  submitDiligence() {
    this.auth.user$.pipe(
      take(1),
      switchMap(user => {
        const postParams: PostDiligenceParams = {
          email: user?.email,
          country: this.data.country,
          ubo: this.ubo_selected,
          type: this.data.type,
          csid: this.data.id || this.data.peopleId,
          name: this.data.name,
          plan: this.packageChosen?.code,
          target: this.data.target
        };

        return this.apiService.postDiligence(postParams).pipe(
          tap(() => {
            this.snackBar.open(
              'Due Diligence is ongoing, you will receive a notification once the report is ready. This may take up to 24h. You can check the report status in the "My Reports" section in your account.',
              'Close',
              { duration: 5000 }
            );
            this.dialogRef.close();
            this.router.navigate(['/myaccount']);
          }),
          catchError((err) => {
            this.snackBar.open(
              'An error occurred during Due Diligence. Please try again later.',
              'Close',
              { duration: 5000 }
            );
            return of(null);
          })
        );
      })
    ).subscribe();
  }

  setPackage(pack: Package) {
    this.auth.isAuthenticated$.pipe(take(1)).subscribe(
      isAuth => {
        if (isAuth) {
          this.packageChosen = pack;
          this.formGroup.patchValue({
            xAmount: this.hasCredits ? 0 : pack.price
          });
        } else {
          this.auth.loginWithPopup();
        }
      }
    );
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      'xCardNum': new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{16}$')]),
      "xCVV": new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      "xExp": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(5)]),
      "xAmount": new FormControl({ value: null, disabled: true }, Validators.required),
      "xBillFirstName": new FormControl(null, Validators.required),
      "xBillLastName": new FormControl(null, Validators.required),
      "xBillStreet": new FormControl(null, Validators.required),
      "xBillCity": new FormControl(null, Validators.required),
      "xBillState": new FormControl(null, Validators.required),
      "xBillZip": new FormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
      "xBillMobile": new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(11)]),
      "termsAccepted": new FormControl(false, Validators.requiredTrue)
    });

    this.apiService.listCredits().pipe(
      take(1),
      tap(credits => {
        // a
        const totalCredits = credits.reduce((total, credit) => total + credit.remaining_amount, 0);
        this.hasCredits = totalCredits > 0;
        this.checkingCredits = false;
      })
    ).subscribe();
  }

  get formControls() {
    return this.formGroup.controls;
  }

  openTermsAndConditions() {
    const dialogRef = this.dialog.open(TosComponent, {
      width: '60%',
      height: '80%',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  dismissError() {
    this.error = false;
  }
}
