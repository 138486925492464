import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../../../core/services/api.service';
import { Credit, CreateCreditDto } from '../../../core/models/credit';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { DatePipe, CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';


@Component({
  selector: 'app-credit-dialog',
  templateUrl: './credits-dialog.component.html',
  styleUrls: ['./credits-dialog.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, FormsModule, MatDialogModule, DatePipe, CommonModule, MatButtonModule, MatTableModule, MatCardModule, ReactiveFormsModule, MatDividerModule]
})
export class CreditDialogComponent {
  creditForm: FormGroup;
  userCredits: Credit[] = [];
  newCredit: CreateCreditDto = { 
    amount: 0, 
    userId: this.data.userId
  };
  displayedColumns: string[] = ['amount', 'startDate', 'endDate', 'actions'];
  private originalCredits: Credit[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private fb: FormBuilder
  ) {
    this.creditForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(0)]],
      startDate: [],
      endDate: []
    });
  }

  addCredit() {
    if (this.creditForm.valid) {

      console.log('this.data', this.data);
      const creditData: CreateCreditDto = {
        amount: this.creditForm.value.amount,
        userId: this.data.user.user_id,
        startDate: this.creditForm.value.startDate,
        endDate: this.creditForm.value.endDate
      };

      console.log('creditData', creditData);
      
      this.apiService.addCredit(creditData).subscribe(() => {
        this.dialogRef.close(true);
      });
    }
  }

  loadCredits() {
    this.apiService.listCreditsForUser(this.data.user.user_id).subscribe({
      next: (credits) => {
        this.userCredits = credits;
        this.originalCredits = JSON.parse(JSON.stringify(credits));
      },
      error: (error) => {
        console.error('Error loading user credits:', error);
      }
    });
  }

  ngOnInit() {
    this.loadCredits();
  }

  updateCredit(credit: Credit) {
    const originalCredit = this.originalCredits.find(c => c.id === credit.id);
    const amountDifference = credit.remaining_amount - (originalCredit?.remaining_amount || 0);
    console.log(originalCredit)
    const updatedCredit = {
      ...credit,
      amount: amountDifference + (originalCredit?.amount || 0)
    };

    console.log('updatedCredit', updatedCredit);

    this.apiService.updateCredit(updatedCredit).subscribe(() => {
      const index = this.originalCredits.findIndex(c => c.id === credit.id);
      if (index !== -1) {
        this.originalCredits[index] = JSON.parse(JSON.stringify(credit));
      }
      this.dialogRef.close(true);
    });
  }

  close() {
    this.dialogRef.close();
  }

  onFieldUpdate(credit: Credit) {
    this.apiService.updateCredit(credit).subscribe({
      next: (updatedCredit) => {
        console.log('Credit updated successfully');
      },
      error: (error) => {
        console.error('Error updating credit:', error);
        this.loadCredits();
      }
    });
  }
}