
   
    <h1 mat-dialog-title>Manage Credits for {{data.user.name}}</h1>
  
  
    <mat-dialog-content>
    <ng-container *ngIf="userCredits.length > 0">
    <table mat-table [dataSource]="userCredits" class="w-full mb-4">
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Remaining Amount </th>
        <td mat-cell *matCellDef="let credit">
          <mat-form-field appearance="outline" class="w-full">
            <input matInput type="number" [(ngModel)]="credit.remaining_amount">
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell *matCellDef="let credit">
          <mat-form-field appearance="outline" class="w-full">
            <input matInput [matDatepicker]="startPicker" [(ngModel)]="credit.startDate">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell *matCellDef="let credit">
          <mat-form-field appearance="outline" class="w-full">
            <input matInput [matDatepicker]="endPicker" [(ngModel)]="credit.endDate">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let credit">
          <button mat-button color="primary" class="mb-5" (click)="updateCredit(credit)">Update</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-divider></mat-divider>
  </ng-container>

    <h3 class="text-lg font-semibold my-4">Add Credit</h3>
    <form [formGroup]="creditForm" (ngSubmit)="addCredit()" class="flex flex-col space-y-4">
      <div class="flex space-x-4">
        <mat-form-field appearance="outline" class="flex-1">
          <mat-label>Amount</mat-label>
          <input matInput type="number" formControlName="amount" placeholder="Amount" required>
          <mat-error *ngIf="creditForm.get('amount')?.hasError('required')">Amount is required</mat-error>
          <mat-error *ngIf="creditForm.get('amount')?.hasError('min')">Amount must be positive</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-1">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="startPicker" formControlName="startDate" placeholder="Start Date">
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
          <mat-error *ngIf="creditForm.get('startDate')?.hasError('required')">Start date is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-1">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="endPicker" formControlName="endDate" placeholder="End Date">
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
          <mat-error *ngIf="creditForm.get('endDate')?.hasError('required')">End date is required</mat-error>
        </mat-form-field>
      </div>

      <button mat-raised-button color="primary" type="submit" [disabled]="!creditForm.valid">
        Add Credit
      </button>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="close()">Close</button>
  </mat-dialog-actions>