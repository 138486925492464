import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, from, of, switchMap } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SubmitParams, GetDiligenceParams, PostDiligenceParams, PutDiligenceParams } from '../models/params';
import { ThreeDsecureResponse } from '../models/payment';
import { AuthService } from '@auth0/auth0-angular'; // Import AuthService
import { CreateCreditDto, Credit } from '../models/credit';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    public http: HttpClient,
    private auth: AuthService
  ) { }

  private getAuthHeaders(): Observable<HttpHeaders> {
    return from(this.auth.getAccessTokenSilently()).pipe(
      mergeMap(token => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return of(headers);
      })
    );
  }

  getCompanies(httParams: SubmitParams, page: number, pageSize: number) {
    const params = new HttpParams()
      .set('country', httParams.country)
      .set('query', httParams.query)
      .set('city', httParams.city as string)
      .set('page', page)
      .set('pageSize', pageSize)
      .set('province', httParams.province as string);

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}getCompanies`, { params: params, headers: headers }))
    );
  }

  getIndividuals(httParams: SubmitParams, page: number, pageSize: number) {
    const params = new HttpParams()
      .set('country', httParams.country)
      .set('query', httParams.query)
      .set('dateOfBirth', httParams.dateOfBirth as string)
      .set('pageSize', pageSize)
      .set('page', page);

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}getIndividuals`, { params: params, headers: headers }))
    );
  }

  getDiligencesByPhase(phase: number) {
    const params = new HttpParams().set('phase', phase);

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}getDiligenceByPhase`, { params: params, headers: headers }))
    );
  }

  getDiligencesByCountryAndCode(httParams: GetDiligenceParams) {
    const params = new HttpParams()
      .set('country', httParams.country)
      .set('code', httParams.code);

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}getDiligence`, { params: params, headers: headers }))
    );
  }

  getDiligencesByEmail() {

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}getDiligenceByEmail`, { headers: headers }))
    );
  }

  getDiligenceById(id: string) {
    const params = new HttpParams().set('jobId', id);
    
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}getDiligenceByJobId`, { params: params, headers: headers }))
    );
  }

  postDiligence(httParams: PostDiligenceParams) {
    console.log('post diligence', httParams);
    const body: any = {
      email: httParams.email,
      country: httParams.country,
      type: httParams.type,
      report: httParams.plan,
      ubo: httParams.ubo,
      name: httParams.name,
      target: httParams.target
    };

    if (httParams.csid) {
      body.csid = httParams.csid;
    }

    return this.getAuthHeaders().pipe(
      mergeMap(headers => 
        this.http.post<any>(`${environment.baseUrl}postDiligence`, body, { headers: headers }).pipe(
          catchError(error => {
            console.error('API error:', error);
            return throwError(error);
          })
        )
      )
    );
  }

  // ADMIN 

  putDiligence(httParams: PutDiligenceParams) {
    const params = new HttpParams().set('uuid', httParams.uuid);

    const body = {
      "phase": httParams.phase,
      "repScore": httParams.repScore,
      "repSumm": httParams.repSumm,
      "critIssueSummary": httParams.critIssueSummary,
      "previousDiligencePhase": httParams.previousDiligencePhase,
      "critIssueDetails": httParams.critIssueDetails,
      "sancIssueSummary": httParams.sancIssueSummary,
      "email": httParams.userEmail
    };

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.put<any>(`${environment.baseUrl}putDiligence`, body, { params: params, headers: headers }))
    );
  }

  getUsers() {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}users`, { headers: headers }))
    );
  }

  postSale(httParams: any): Observable<any> {
    const threeDSecureEndpoint = environment.cardKnoxApiEndpoint;
    return this.http.post<ThreeDsecureResponse>(threeDSecureEndpoint, {}).pipe(
      switchMap((ck3DS: ThreeDsecureResponse) => {
        const body = {
          "xCommand": "cc:sale",
          "xAllowDuplicate": "TRUE",
          "xSoftwareName": 'name', // da config
          "xSoftwareVersion": "1.0.0",
          "xVersion": "4.5.9", // da config

          // dati specifici della chiamata da form
          "xCVV": httParams.xCVV,
          "xExp": httParams.xExp,
          "xCardNum": httParams.xCardNum,
          "xAmount": httParams.xAmount,
          "xEmail": httParams.xEmail,
          "xCustReceipt": true,
          //dati specifici di 3ds security
          "x3dsReferenceId": ck3DS.refnum,
          "x3dsInitializeStatus": ck3DS.status,

          //dati aggiuntivi obbligatori per 3ds security
          "xBillFirstName": httParams.xBillFirstName,
          "xBillLastName": httParams.xBillLastName,
          "xBillStreet": httParams.xBillStreet,
          "xBillCity": httParams.xBillCity,
          "xBillState": httParams.xBillState,
          "xBillZip": httParams.xBillZip,
          "xBillMobile": httParams.xBillMobile,
        };
        return this.getAuthHeaders().pipe(
          mergeMap(headers => this.http.post<any>(`${environment.baseUrl}sale`, body, { headers: headers }))
        );
      })
    );
  }

  listCredits() {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<[Credit]>(`${environment.baseUrl}credits`, { headers: headers }))
    );
  }

  listCreditsForUser(userId: string) {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<[Credit]>(`${environment.baseUrl}credits/user/${userId}`, { headers: headers }))
    );
  }

  addCredit(credit: CreateCreditDto) {
    const body =  {amount: credit.amount, userId: credit.userId, startDate: credit.startDate, endDate: credit.endDate};
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.post<any>(`${environment.baseUrl}credits/add`, body, { headers: headers }))
    );
  }

  updateCredit(credit: Credit) {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.put<any>(`${environment.baseUrl}credits/${credit.id}`, credit, { headers: headers }))
    );
  }
}