import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreditDialogComponent } from '../../shared/components/credits-dialog/credits-dialog.component';
import { ApiService } from '../../core/services/api.service';
import { MatTableModule } from '@angular/material/table';
import { Credit } from '../../core/models/credit';
import { PageEvent } from '@angular/material/paginator';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';

interface User {
    id: number;
    name: string;
    email: string;
    reportsGenerated: number;
    moneySpent: number;
    creditsLeft: number;
    credits: Credit[];
  }

interface UserResponse {
  start: number;
  limit: number;
  length: number;
  users: User[];
  total: number;
}

@Component({
  selector: 'app-accounts',
  templateUrl: './account.component.html',
  imports: [MatTableModule, MatPaginatorModule, NavbarComponent],
  standalone: true
})
export class AccountsComponent implements OnInit {
  users: User[] = [];
  displayedColumns: string[] = ['name', 'email', 'actions'];
  pageSize = 10;
  pageIndex = 0;
  totalUsers = 0;

  constructor(private apiService: ApiService, private dialog: MatDialog) {}

  ngOnInit() {
    this.loadUsers();
  }

  loadUsers() {
    this.apiService.getUsers().subscribe((response: UserResponse) => {
      this.users = response.users;
      this.totalUsers = response.total;
    });
  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadUsers();
  }

  openCreditDialog(user: User) {
    const dialogRef = this.dialog.open(CreditDialogComponent, {
      width: '1000px',
      height: '500px',
      data: { user: user }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadUsers(); // Reload users after credit update
      }
    });
  }
}